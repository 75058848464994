.contact__border {
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.contact__h2 {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.contact__list {
  padding: 0;
}
.contact__text {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  margin-bottom: 36px;
}
.contact__list--bottom {
  padding: 0;
  margin: 0;
}
.contact__adresse, .contact__name {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
}
.contact__adresse {
  margin-bottom: 36px;
}
.contact__adresse a {
  color: #FFFFFF;
  text-decoration: none;
}
.contact__reseau {
  display: flex;
  max-width: 60%;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 27px;
}
.contact__img {
  height: 20px;
}
.contact__copyright {
  font-family: "manrope", sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 11px;
  text-align: center;
  margin-bottom: 20px;
}
.contact__copyright a {
  color: #FFFFFF;
}
.contact__buttonAnnexe {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  color: white;
  width: fit-content;
  padding-bottom: 12px;
  overflow: hidden;
  margin-bottom: 47px;
}
.contact__buttonAnnexe--anim {
  padding: 10px 0;
  font-family: "Manrope", sans-serif;
  background: transparent;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  font-size: 15px;
}
.contact__buttonAnnexe:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}
.contact__buttonAnnexe:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.contact__buttonAnnexe a {
  padding: 10px 25px;
  position: relative;
  z-index: 2;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}
.contact__buttonAnnexe:hover a {
  color: black;
}

li {
  list-style: none;
}

@media (min-width: 750px) {
  .contact__border {
    height: 2px;
  }
  .contact__h2 {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .contact__text {
    font-size: 20px;
    max-width: 90%;
  }
  .contact__adresse, .contact__name {
    font-size: 20px;
  }
  .contact__adresse {
    margin-bottom: 36px;
  }
  .contact__reseau {
    max-width: 30%;
  }
  .contact__img {
    height: 27px;
  }
  .contact__copyright {
    font-size: 20px;
  }
  .contact__buttonAnnexe {
    padding-bottom: 17px;
  }
  .contact__buttonAnnexe--anim {
    padding: 15px 0;
    font-size: 20px;
  }
  .contact__buttonAnnexe a {
    padding: 15px 25px;
  }
}
@media (min-width: 1200px) {
  .contact-desktop {
    display: block;
  }
  .contact__list {
    display: flex;
    flex-direction: column;
  }
  .contact__list--top {
    margin: 0;
    padding: 0;
  }
  .contact__list--bottom {
    display: flex;
    justify-content: space-between;
    width: 50vw;
    padding: 0;
    margin: 0;
    align-items: flex-end;
    margin-bottom: 69px;
  }
  .contact__adresse {
    margin: 0;
  }
  .contact__border {
    width: 33%;
  }
  .contact__h2 {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .contact__text {
    font-size: 22px;
    width: 50%;
  }
  .contact__text p {
    margin-top: 0;
  }
  .contact__adresse, .contact__name {
    font-size: 22px;
  }
  .contact__reseau {
    width: 25%;
    margin: 0;
    padding: 0;
    margin-bottom: 93px;
  }
  .contact__img {
    height: 29px;
    margin-right: 22px;
  }
  .contact__copyright {
    font-size: 17px;
    margin: 0;
  }
  .contact__copyright a {
    color: #FFFFFF;
    text-decoration: none;
  }
  .contact__copyright a:hover {
    text-decoration: underline;
  }
  .contact__buttonAnnexe {
    padding-bottom: 17px;
  }
  .contact__buttonAnnexe--anim {
    padding: 15px 0;
    font-size: 22px;
  }
}
header {
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100vw;
}

.header--sticky {
  position: fixed;
  top: -100px;
  width: 100%;
  transition: top 0.3s;
  z-index: 1000;
  width: 100vw;
}
.header--sticky.visible {
  top: 0;
  width: 100vw;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in;
}
.menu.active {
  transform: translateY(0);
}
.menu__nav--toggle {
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  z-index: 30;
  padding: 0;
}
.menu__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto 0;
  padding: 0;
  margin-bottom: 0;
}
.menu__el {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 15px;
  color: #FFFFFF;
  padding: 46.5px;
  letter-spacing: 5px;
}
.menu__el img {
  padding-left: 7px;
  width: 7px;
  align-items: center;
}
.menu__el a {
  color: #FFFFFF;
  text-decoration: none;
}
.menu__bar {
  width: 30px;
  height: 3px;
  background-color: #FFFFFF;
  margin: 3px 0;
  transition: transform 0.3s, opacity 0.3s;
  border-radius: 3px;
}

body.menu--open,
html.menu--open {
  overflow: hidden;
}

.nav__logo {
  width: 29px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 30;
}
.nav--desktop {
  display: none;
}
.nav--mobile {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: space-between;
}
.nav--credit {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: space-between;
}
.nav__list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.nav__el {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  color: #FFFFFF;
  margin: 0;
  display: flex;
  margin-right: 20px;
}
.nav__el img {
  width: 5px;
  padding-left: 5px;
}
.nav__el a {
  color: #FFFFFF;
  text-decoration: none;
}

.menu__nav--toggle.active .menu__bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu__nav--toggle.active .menu__bar:nth-child(2) {
  opacity: 0;
}

.menu__nav--toggle.active .menu__bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

@media (min-width: 750px) {
  .menu__nav--toggle {
    right: 36px;
  }
  .menu__bar {
    width: 36px;
    height: 4px;
  }
  .menu__el {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 27px;
    color: #FFFFFF;
    padding: 46.5px;
    letter-spacing: 5px;
  }
  .menu__el img {
    padding-left: 10px;
    width: 12px;
    align-items: center;
  }

  .menu__nav--toggle.active .menu__bar:nth-child(1) {
    transform: translateY(11px) rotate(45deg);
  }

  .nav__logo {
    width: 36px;
    margin-top: 27px;
    margin-left: 27px;
    margin-bottom: 27px;
  }
  .nav__el {
    font-size: 15px;
    margin-right: 40px;
  }
  .nav__el img {
    width: 7px;
    padding-left: 7px;
  }
}
@media (min-width: 1200px) {
  header {
    width: 100%;
    height: 96px;
    display: flex;
    box-sizing: border-box;
    padding-left: 93px;
    padding-right: 93px;
  }

  .nav {
    width: 100vw;
    height: 96px;
    background-color: #000000;
  }
  .nav--desktop {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
  }
  .nav--mobile {
    display: none;
  }
  .nav__logo {
    width: 31px;
    margin: auto;
  }
  .nav__list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  .nav__el {
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #FFFFFF;
    margin: auto 0;
    display: flex;
    align-items: center;
    margin-left: 69px;
  }
  .nav__el img {
    padding-left: 7px;
    width: 7px;
  }
  .nav__el a {
    color: #FFFFFF;
    text-decoration: none;
  }
}
.home {
  margin-bottom: 30vh;
}
.home__title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  margin: 0;
  padding-top: 112px;
  margin-bottom: 84px;
  color: #FFFFFF;
  font-size: 27px;
}
.home__title--extralight {
  font-weight: 100;
  display: block;
  font-size: 47px;
  line-height: 112px;
}
.home__title--extrabold {
  font-weight: 800;
  font-size: 47px;
  line-height: 63px;
}
.home__text {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
}
.home__text--moi {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}
.home__text--portfolio {
  padding: 0;
  margin: 0;
}

@media (min-width: 360px) {
  .home__title--extrabold {
    font-size: 63px;
  }
}
@media (min-width: 750px) {
  .home {
    margin-bottom: 40vh;
  }
  .home__title {
    padding-top: 165px;
    margin-bottom: 123px;
  }
  .home__title--extralight {
    font-size: 93px;
    line-height: 163px;
  }
  .home__title--extrabold {
    font-size: 93px;
    line-height: 112px;
  }
  .home__text {
    font-size: 29px;
    width: 90%;
  }
  .home__text--moi {
    margin-bottom: 29px;
  }
}
@media (min-width: 1200px) {
  .home {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-bottom: 30vh;
  }
  .home__title {
    padding-top: 165px;
    margin-bottom: 52px;
    position: relative;
    font-size: 93px;
  }
  .home__text {
    font-size: 29px;
    align-self: end;
    width: 40%;
  }
  .home__text--moi {
    margin-bottom: 29px;
  }

  .scroll-downs {
    position: absolute;
    bottom: 1%;
    left: 50%;
    width: 34px;
    height: 55px;
    transform: translateX(-50%);
    transform: translateY(-50%);
  }

  .mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #FFFFFF;
    border-radius: 25px;
    opacity: 0.75;
  }

  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #FFFFFF;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
  }

  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;
    }
  }
}
@media (min-width: 1200px) and (max-height: 800px) {
  .home__title {
    padding-top: 123px;
  }
  .home__text {
    font-size: 22px;
  }
  .home__title {
    font-size: 69px;
  }
}
.propos {
  margin-bottom: 30vh;
}
.propos__border {
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.propos__h2 {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.propos__text {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
}
.propos__img {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: auto;
  margin-top: 20px;
}
.propos__perso {
  width: 50%;
  margin-top: 20px;
}

@media (min-width: 750px) {
  .propos {
    margin-bottom: 35vh;
  }
  .propos__border {
    height: 2px;
  }
  .propos__h2 {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .propos__text {
    font-size: 27px;
  }
  .propos__img {
    width: 60%;
    margin: auto;
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .propos {
    margin-bottom: 30vh;
  }
  .propos__border {
    width: 33%;
  }
  .propos__h2 {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .propos__text {
    font-size: 29px;
    width: 50%;
  }
  .propos__img {
    width: 40%;
  }
  .propos__container {
    display: flex;
    flex-direction: initial;
  }
}
@media (min-width: 1200px) and (max-height: 800px) {
  .propos__text {
    font-size: 22px;
  }
  .propos__img {
    width: 30%;
  }
}
.projets {
  margin-bottom: 35vh;
}
.projets__border {
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.projets__h2 {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.projets__img {
  width: 70vw;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.projets__description {
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.projets__content {
  margin-left: 50px;
}
.projets__content a {
  text-decoration: none;
}
.projets__slider {
  display: flex;
  justify-content: start;
}

@media (min-width: 750px) {
  .projets {
    margin-bottom: 35vh;
  }
  .projets__border {
    height: 2px;
  }
  .projets__h2 {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .projets__img {
    width: 60vw;
  }
  .projets__description {
    font-size: 22px;
  }
  .projets__content {
    margin-left: 63px;
  }
}
@media (min-width: 1200px) {
  .projets {
    margin-bottom: 30vh;
  }
  .projets__border {
    width: 33%;
  }
  .projets__h2 {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .projets__description {
    color: #FFFFFF;
    font-size: 22px;
    margin-top: 22px;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .projets__content {
    margin-left: 50px;
  }
  .projets__slider {
    width: 200%;
    display: flex;
    justify-content: center;
  }
  .projets__img {
    max-width: 100%;
    width: 30vw;
    transition: transform 0.3s ease-in-out;
  }
  .projets__img:hover {
    transform: translateY(-10px);
  }
}
@media (min-width: 1200px) and (max-height: 800px) {
  .projets__description {
    font-size: 17px;
  }
}
::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #000000;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  overflow-x: hidden;
}

p {
  line-height: 140%;
}

main {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.bar {
  position: fixed;
  height: 2px;
  background-color: rgb(0, 0, 0);
  width: 100%;
  bottom: 0;
  z-index: 50;
}
.bar--progress {
  width: 0%;
  height: 2px;
  background-color: #FFFFFF;
}

@media (min-width: 750px) {
  main {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  main {
    padding-left: 93px;
    padding-right: 93px;
  }

  html {
    cursor: none;
  }

  .circle {
    z-index: 1001;
    --circle-size: 40px;
    position: fixed;
    height: 40px;
    width: 40px;
    border: 2px solid white;
    border-radius: 100%;
    top: -20px;
    left: -20px;
    pointer-events: none;
    transition: width 0.3s ease, height 0.3s ease;
    mix-blend-mode: difference;
  }

  a,
button {
    cursor: none;
  }

  .circle.hover {
    height: 60px;
    width: 60px;
    top: -30px;
    left: -30px;
  }
}
.contact__reseau li:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.nav__el {
  display: table-cell;
  position: relative;
  transition: transform 0.3s ease;
}
.nav__el:hover {
  transform: scale(1.1);
}
.nav__el a:after {
  bottom: -3px;
  content: "";
  height: 1px;
  left: 50%;
  border-radius: 5px;
  position: absolute;
  background: #FFFFFF;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0;
}
.nav__el a:hover:after {
  width: 100%;
  left: 0;
}
.nav__el--active a:after {
  width: 100%;
  left: 0;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.modal__open body {
  display: none;
}
.modal__open body .modal {
  display: block;
}
.modal__h2 {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  margin: 0;
  color: #FFFFFF;
  margin-bottom: 10%;
  margin-top: 5%;
}
.modal__h2--extralight {
  font-size: 27px;
  font-weight: 100;
  padding: 0;
}
.modal__h2--extrabold {
  font-size: 36px;
  font-weight: 800;
  padding: 0;
}
.modal__construction {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 27px;
}
.modal__img {
  width: 90vw;
  border-radius: 13px;
  margin-top: 36px;
}
.modal__listUtils {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  padding: 0;
  margin: 0;
  color: #FFFFFF;
  display: flex;
  justify-content: space-around;
  margin-top: 22px;
  margin-bottom: 47px;
}
.modal__el {
  border: solid 1px #FFFFFF;
  border-radius: 5px;
  font-size: 15px;
  padding: 6px;
}
.modal__paragraph {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
}
.modal__paraImg {
  font-size: 20px;
}
.modal__listButton {
  margin: 0;
  padding: 0;
  margin-top: 47px;
  display: flex;
  justify-content: center;
}
.modal__listButton--block {
  display: block;
}
.modal__elButton {
  border: solid 1px #FFFFFF;
  border-radius: 50px;
  color: #FFFFFF;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  max-width: max-content;
  font-weight: 600;
  transition: transform 0.3s ease;
}
.modal__elButton:hover {
  transform: scale(1.1);
}
.modal__elButton:nth-child(1) {
  margin-bottom: 20px;
}
.modal__elButton:nth-child(2) a {
  background-position: 93%;
}
.modal__elButton a {
  color: #FFFFFF;
  text-decoration: none;
  background-image: url(../assets/images/button_site_goTo.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 90%;
  padding: 11px;
  padding-top: 9px;
  padding-right: 40px;
}
.modal__pucePara {
  width: 20px;
  height: 20px;
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  background-image: url(../assets/images/puce.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
}
.modal__buttonImg {
  margin-left: 22px;
}
.modal__video {
  margin-top: 47px;
  margin-bottom: 6px;
  width: 90vw;
}
.modal__group {
  color: #FFFFFF;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-size: 11px;
  max-width: 80%;
  display: flex;
  margin: auto;
  text-align: center;
}
.modal-content {
  background-color: #000000;
  padding: 20px;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
}
.modal--open {
  overflow: hidden;
}
.modal--open header {
  display: none;
}
.modal--open .bar {
  display: none;
}
.modal--open .modal {
  overflow: scroll;
}
.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.modal__close img {
  width: 25px;
}
.modal__suggestionTitle {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.modal__suggestionImage {
  display: flex;
  justify-content: space-around;
}
.modal__suggestionImage--1 {
  display: block;
  margin: 0 auto;
}
.modal__suggestionImage--2 {
  display: block;
  margin: 0 auto;
}
.modal__suggestionImage img {
  width: 40vw;
  border-radius: 8px;
}
.modal__suggestionImage p {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 11px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}
.modal__suggestionImage a {
  text-decoration: none;
}

@media (min-width: 750px) {
  .modal__h2 {
    margin-bottom: 5%;
  }
  .modal__h2--extralight {
    font-size: 52px;
  }
  .modal__h2--extrabold {
    font-size: 69px;
  }
  .modal__construction {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-top: 39px;
    font-size: 27px;
  }
  .modal__contenu {
    max-width: 80%;
    margin: 0 auto;
  }
  .modal__img {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 39px;
  }
  .modal__projet {
    display: flex;
    justify-content: center;
  }
  .modal__listUtils {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 22px;
    margin-bottom: 52px;
  }
  .modal__el {
    border: solid 2px #FFFFFF;
    border-radius: 5px;
    font-size: 17px;
    padding: 6px;
  }
  .modal__paragraph {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    color: #FFFFFF;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
  }
  .modal__paraImg {
    font-size: 24px;
  }
  .modal__listButton {
    margin: 0;
    padding: 0;
    margin-top: 52px;
  }
  .modal__elButton {
    font-size: 22px;
    background-size: 22px;
    padding: 11px;
    padding-top: 9px;
  }
  .modal__elButton:nth-child(1) {
    margin-bottom: 22px;
  }
  .modal__elButton:nth-child(2) a {
    background-position: 97%;
  }
  .modal__elButton a {
    color: #FFFFFF;
    text-decoration: none;
    background-image: url(../assets/images/button_site_goTo.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 95%;
    padding: 7px;
    padding-top: 8px;
    padding-right: 46px;
  }
  .modal__pucePara {
    width: 30px;
    height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-image: url(../assets/images/puce.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .modal__video {
    margin-top: 52px;
    margin-bottom: 7px;
    width: 90vw;
  }
  .modal__group {
    font-size: 17px;
  }
  .modal-content {
    padding: 5%;
  }
  .modal--open {
    overflow: hidden;
  }
  .modal--open header {
    display: none;
  }
  .modal--open .bar {
    display: none;
  }
  .modal--open .modal {
    overflow: scroll;
  }
  .modal__close {
    top: 39px;
    right: 39px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: fixed;
  }
  .modal__close img {
    width: 30px;
  }
  .modal__suggestionTitle {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .modal__suggestionImage img {
    width: 30vw;
  }
  .modal__suggestionImage p {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .modal__h2 {
    margin-bottom: 10%;
  }
  .modal__h2--extralight {
    font-size: 52px;
  }
  .modal__h2--extrabold {
    font-size: 69px;
  }
  .modal__construction {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 36px;
  }
  .modal__contenant {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .modal__contenu {
    max-width: 100%;
  }
  .modal--left {
    max-width: 50%;
    margin: 0 auto;
  }
  .modal--right {
    max-width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal__img {
    width: 40vw;
    margin: 0;
    margin-top: 78px;
  }
  .modal__projet {
    display: flex;
    justify-content: center;
  }
  .modal__pucePara {
    width: 30px;
    height: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-image: url(../assets/images/puce.svg);
    background-size: 20px;
  }
  .modal__listUtils {
    max-width: 30vw;
    margin: 0 auto;
    margin-top: 29px;
    margin-bottom: 52px;
  }
  .modal__el {
    border-top: solid 2px #FFFFFF;
    border-radius: 10px;
    font-size: 20px;
    padding: 7px;
  }
  .modal__paragraph {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    color: #FFFFFF;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 60%;
    font-weight: 300;
  }
  .modal__paraImg {
    font-size: 27px;
    max-width: 80%;
  }
  .modal__listButton {
    margin: 0;
    padding: 0;
    margin-top: 52px;
    display: flex;
    justify-content: center;
  }
  .modal__elButton {
    font-size: 22px;
    background-size: 22px;
    padding: 11px;
    padding-top: 9px;
  }
  .modal__elButton:nth-child(1) {
    margin-right: 22px;
    margin-bottom: 0px;
  }
  .modal__elButton:nth-child(2) a {
    background-position: 96%;
  }
  .modal__elButton a {
    color: #FFFFFF;
    text-decoration: none;
    background-image: url(../assets/images/button_site_goTo.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 94%;
    padding: 11px;
    padding-top: 8px;
    padding-right: 46px;
  }
  .modal__video {
    margin-top: 52px;
    margin-bottom: 11px;
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .modal__group {
    font-size: 22px;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  .modal-content {
    padding: 5%;
    padding-top: 52px;
  }
  .modal--open {
    overflow: hidden;
  }
  .modal--open header {
    display: none;
  }
  .modal--open .bar {
    display: none;
  }
  .modal--open .modal {
    overflow: scroll;
  }
  .modal__close {
    top: 69px;
    right: 69px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
  .modal__close img {
    width: 39px;
  }
  .modal__suggestionTitle {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .modal__suggestionImage--1 {
    padding-left: 10%;
  }
  .modal__suggestionImage--2 {
    padding-right: 10%;
  }
  .modal__suggestionImage img {
    width: 25vw;
    transition: transform 0.3s ease-in-out;
  }
  .modal__suggestionImage img:hover {
    transform: translateY(-10px);
  }
  .modal__suggestionImage p {
    font-size: 22px;
  }
}
.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 63px;
  margin-bottom: 63px;
}
.slider__list {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin: 0;
  padding: 0;
  list-style: none;
}
.slider__el {
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
}
.slider__el img {
  width: 200px;
}
.slider__prev, .slider__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
}
.slider__prev {
  left: 10px;
}
.slider__next {
  right: 10px;
}
.slider__prev img, .slider__next img {
  width: 20px;
}

@media (min-width: 1200px) {
  .slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 93px;
    margin-bottom: 93px;
  }
  .slider__list {
    display: flex;
    transition: transform 0.5s ease-in-out;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .slider__el {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  .slider__el img {
    width: 300px;
  }
  .slider__prev, .slider__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
  }
  .slider__prev {
    left: 10px;
  }
  .slider__next {
    right: 10px;
  }
  .slider__prev img, .slider__next img {
    width: 20px;
  }
}
.credits {
  margin-bottom: 30vh;
}
.credits__title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  margin: 0;
  padding-top: 112px;
  margin-bottom: 84px;
  color: #FFFFFF;
  font-weight: 800;
  font-size: 63px;
  line-height: 63px;
}
.credits__sousTitle {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.credits__el {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
  list-style: circle;
  margin-bottom: 10px;
}
.credits__el a {
  color: #FFFFFF;
  text-decoration: none;
}

@media (min-width: 750px) {
  .credits__title {
    padding-top: 165px;
    margin-bottom: 123px;
    font-size: 93px;
    line-height: 112px;
  }
  .credits__sousTitle {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .credits__list {
    padding: 0px;
  }
  .credits__el {
    font-size: 22px;
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .credits__sousTitle {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .credits__el a:hover {
    text-decoration: underline;
  }
}
.annexe {
  margin-bottom: 20vh;
}
.annexe__title {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  margin: 0;
  padding-top: 84px;
  margin-bottom: 84px;
  color: #FFFFFF;
  font-weight: 800;
  font-size: 47px;
  line-height: 63px;
}
.annexe__border {
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.annexe__h2 {
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 63px;
  font-size: 27px;
}
.annexe__h2--test {
  display: none;
}
.annexe__button {
  background-image: url("../assets/images/play.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s;
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.annexe__img {
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}
.annexe__jeu {
  display: none;
  grid-template-columns: repeat(3, 30vw);
  grid-template-rows: repeat(3, 30vw);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 0 auto;
  margin-top: 47px;
  justify-content: center;
}
.annexe__grid--1 {
  grid-area: 1/1/2/2;
}
.annexe__grid--2 {
  grid-area: 1/3/2/4;
}
.annexe__grid--3 {
  grid-area: 3/1/4/2;
}
.annexe__grid--4 {
  grid-area: 3/3/4/4;
}
.annexe__grid--5 {
  grid-area: 2/2/3/3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.annexe__buttonImg {
  padding: 0;
  border: none;
  background-color: transparent;
}
.annexe__instructions {
  margin-bottom: 20vh;
}
.annexe__instructions .annexe__buttonNext {
  display: flex;
  margin: 0 auto;
  margin-top: 63px;
}
.annexe__instructionsSubtitle {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #FFFFFF;
  font-size: 20px;
}
.annexe__instructionsList {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  padding-left: 20px;
}
.annexe__instructionsEl {
  margin-top: 10px;
  color: #FFFFFF;
  list-style: circle;
  line-height: 140%;
}
.annexe__scoreBest {
  margin-top: 20px;
}
.annexe__score {
  margin-top: 47px;
}
.annexe__buttonNext {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 1;
  color: white;
  width: fit-content;
  padding-bottom: 12px;
  overflow: hidden;
  padding: 10px 25px;
  position: relative;
  z-index: 2;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-top: 63px;
}
.annexe__buttonNext--anim {
  font-family: "Manrope", sans-serif;
  background: transparent;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  font-size: 15px;
}
.annexe__buttonNext:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}
.annexe__buttonNext:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.annexe__buttonNext:hover {
  color: black;
}
.annexe__text {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #FFFFFF;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
  justify-content: center;
  text-align: center;
  display: flex;
}
.annexe__rechercheText {
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 1px;
  margin-bottom: 63px;
}
.annexe__rechercheText--block {
  margin-top: 15px;
  display: block;
}
.annexe__scoreBest {
  font-size: 15px;
  margin-top: 15px;
  font-weight: 300;
}
.annexe__result {
  display: none;
  margin-top: 15vh;
}
.annexe__result .annexe__buttonNext {
  display: flex;
  margin: 0 auto;
  margin-top: 63px;
}
.annexe__compteur {
  display: none;
}
.annexe__compteurPara {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 1px;
  display: flex;
  margin: 0 auto;
}
.annexe__answer--correct {
  border: 2px solid rgb(0, 255, 0);
}
.annexe__answer--incorrect {
  border: 2px solid red;
}

@media (min-width: 750px) {
  .annexe__title {
    padding-top: 165px;
    margin-bottom: 123px;
    font-size: 93px;
    line-height: 112px;
  }
  .annexe__border {
    height: 2px;
  }
  .annexe__h2 {
    margin-top: 84px;
    margin-bottom: 63px;
    font-size: 36px;
  }
  .annexe__buttonNext {
    padding: 15px 25px;
    padding-bottom: 17px;
    font-size: 20px;
    margin-top: 69px;
  }
  .annexe__instructions .annexe__buttonNext {
    margin-top: 69px;
  }
  .annexe__result .annexe__buttonNext {
    margin-top: 69px;
  }
  .annexe__scoreBest {
    margin-top: 22px;
  }
  .annexe__button {
    height: 85%;
    width: 85%;
  }
  .annexe__jeu {
    grid-template-columns: repeat(3, 25vw);
    grid-template-rows: repeat(3, 25vw);
  }
  .annexe__score {
    margin-top: 52px;
  }
  .annexe__instructionsSubtitle {
    font-size: 22px;
    margin-bottom: 27px;
  }
  .annexe__instructionsList {
    font-size: 17px;
    padding: 0;
  }
  .annexe__instructionsEl {
    margin-top: 15px;
  }
  .annexe__text {
    font-size: 29px;
  }
  .annexe__scoreBest {
    font-size: 22px;
    margin-top: 22px;
  }
  .annexe__rechercheText {
    font-size: 20px;
    margin-bottom: 84px;
    max-width: 80%;
  }
  .annexe__rechercheText--block {
    margin-top: 20px;
    display: block;
  }
  .annexe__compteurPara {
    font-size: 27px;
  }
  .annexe__answer--correct {
    border-width: 3px;
  }
  .annexe__answer--incorrect {
    border-width: 3px;
  }
}
@media (min-width: 1200px) {
  .annexe__title {
    padding-top: 165px;
    margin-bottom: 52px;
    position: relative;
    font-size: 93px;
  }
  .annexe__border {
    margin: 0;
    width: 33%;
  }
  .annexe__h2 {
    margin-top: 123px;
    margin-bottom: 93px;
    font-size: 52px;
  }
  .annexe__button {
    width: 70%;
    height: 70%;
  }
  .annexe__img {
    border-radius: 7px;
  }
  .annexe__jeu {
    margin-top: 52px;
    margin-bottom: 123px;
    grid-template-columns: repeat(3, 12vw);
    grid-template-rows: repeat(3, 12vw);
  }
  .annexe__scoreBest {
    margin-top: 29px;
  }
  .annexe__buttonNext {
    padding: 15px 25px;
    padding-bottom: 17px;
    font-size: 22px;
    margin-top: 123px;
  }
  .annexe__instructions {
    margin-top: 123px;
    margin-bottom: 123px;
  }
  .annexe__instructions .annexe__buttonNext {
    margin-top: 123px;
  }
  .annexe__result {
    margin-top: 123px;
    margin-bottom: 20vh;
  }
  .annexe__result .annexe__buttonNext {
    margin-top: 123px;
  }
  .annexe__instructionsSubtitle {
    font-size: 29px;
    margin-bottom: 52px;
  }
  .annexe__instructionsList {
    font-size: 22px;
  }
  .annexe__instructionsEl {
    margin-top: 22px;
  }
  .annexe__text {
    font-size: 39px;
  }
  .annexe__scoreBest {
    font-size: 22px;
    margin-top: 29px;
  }
  .annexe__rechercheText {
    font-size: 22px;
    margin-bottom: 20vh;
    max-width: 60%;
  }
  .annexe__rechercheText--block {
    margin-top: 22px;
    display: block;
  }
  .annexe__compteurPara {
    font-size: 39px;
  }
}