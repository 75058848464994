.home {
    margin-bottom: 30vh;

    &__title {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        margin: 0;
        padding-top: 112px;
        margin-bottom: 84px;
        color: $c-white;
        font-size: 27px;

        &--extralight {
            font-weight: 100;
            display: block;
            font-size: 47px;
            line-height: 112px;
        }

        &--extrabold {
            font-weight: 800;
            font-size: 47px;
            line-height: 63px;
        }
    }

    &__text {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 20px;
        color: $c-white;

        &--moi {
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
        }

        &--portfolio {
            padding: 0;
            margin: 0;
        }
    }
}

@media (min-width : 360px) {
    .home {
        &__title {
            &--extrabold {
                font-size: 63px;
            }
        }
    }
}

@media (min-width : 750px) {
    .home {
        margin-bottom: 40vh;

        &__title {
            padding-top: 165px;
            margin-bottom: 123px;

            &--extralight {
                font-size: 93px;
                line-height: 163px;
            }

            &--extrabold {
                font-size: 93px;
                line-height: 112px;
            }
        }

        &__text {
            font-size: 29px;
            width: 90%;

            &--moi {
                margin-bottom: 29px;
            }
        }
    }
}

@media (min-width : 1200px) {
    .home {
        display: flex;
        flex-direction: column;
        height: 100vh;
        margin-bottom: 30vh;

        &__title {
            padding-top: 165px;
            margin-bottom: 52px;
            position: relative;
            font-size: 93px;
        }

        &__text {
            font-size: 29px;
            align-self: end;
            width: 40%;

            &--moi {
                margin-bottom: 29px;
            }
        }
    }

    .scroll-downs {
        position: absolute;
        bottom: 1%;
        left: 50%;
        width: 34px;
        height: 55px;
        transform: translateX(-50%);
        transform: translateY(-50%);
    }

    .mousey {
        width: 3px;
        padding: 10px 15px;
        height: 35px;
        border: 2px solid $c-white;
        border-radius: 25px;
        opacity: 0.75;
    }

    .scroller {
        width: 3px;
        height: 10px;
        border-radius: 25%;
        background-color: $c-white;
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(.15, .41, .69, .94);
        animation-iteration-count: infinite;
    }

    @keyframes scroll {
        0% {
            opacity: 0;
        }

        10% {
            transform: translateY(0);
            opacity: 1;
        }

        100% {
            transform: translateY(15px);
            opacity: 0;
        }
    }

    @media (max-height : 800px) {
        .home {
            &__title {
                padding-top: 123px;
            }

            &__text {
                font-size: 22px;
            }

            &__title {
                font-size: 69px;
            }
        }
    }
}