.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;

    &__open {
        body {
            display: none;

            .modal {
                display: block;
            }
        }
    }

    &__h2 {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        margin: 0;
        color: $c-white;
        margin-bottom: 10%;
        margin-top: 5%;

        &--extralight {
            font-size: 27px;
            font-weight: 100;
            padding: 0;
        }

        &--extrabold {
            font-size: 36px;
            font-weight: 800;
            padding: 0;
        }
    }

    &__construction {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        display: flex;
        justify-content: center;
        color: $c-white;
        text-transform: uppercase;
        font-size: 15px;
        margin-top: 27px;
    }

    &__img {
        width: 90vw;
        border-radius: 13px;
        margin-top: 36px;
    }

    &__listUtils {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 800;
        padding: 0;
        margin: 0;
        color: $c-white;
        display: flex;
        justify-content: space-around;
        margin-top: 22px;
        margin-bottom: 47px;
    }

    &__el {
        border: solid 1px $c-white;
        border-radius: 5px;
        font-size: 15px;
        padding: 6px;
    }

    &__paragraph {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 300;
    }

    &__paraImg {
        font-size: 20px;
    }

    &__listButton {
        margin: 0;
        padding: 0;
        margin-top: 47px;
        display: flex;
        justify-content: center;

        &--block {
            display: block;
        }
    }

    &__elButton {
        border: solid 1px $c-white;
        border-radius: 50px;
        color: $c-white;
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        max-width: max-content;
        font-weight: 600;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }

        &:nth-child(1) {
            margin-bottom: 20px;
        }

        &:nth-child(2) a {
            background-position: 93%;
        }


        a {
            color: $c-white;
            text-decoration: none;
            background-image: url(../assets/images/button_site_goTo.svg);
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: 90%;
            padding: 11px;
            padding-top: 9px;
            padding-right: 40px;
        }
    }

    &__pucePara {
        width: 20px;
        height: 20px;
        display: flex;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 15px;
        background-image: url(../assets/images/puce.svg);
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__buttonImg {
        margin-left: 22px;
    }

    &__video {
        margin-top: 47px;
        margin-bottom: 6px;
        width: 90vw;
    }

    &__group {
        color: $c-white;
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-size: 11px;
        max-width: 80%;
        display: flex;
        margin: auto;
        text-align: center;
    }

    &-content {
        background-color: $c-bg;
        padding: 20px;
        width: 100vw;
        min-height: 100vh;
        box-sizing: border-box;
    }

    &--open {
        overflow: hidden;

        header {
            display: none;
        }

        .bar {
            display: none;
        }

        .modal {
            overflow: scroll;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        margin: 0;
        padding: 0;

        img {
            width: 25px;
        }
    }

    &__suggestionTitle {
        font-family: "Orbitron", sans-serif;
        font-style: normal;
        color: $c-white;
        margin-top: 84px;
        margin-bottom: 63px;
        font-size: 27px;
    }

    &__suggestionImage {
        display: flex;
        justify-content: space-around;

        &--1 {
            display: block;
            margin: 0 auto;
        }

        &--2 {
            display: block;
            margin: 0 auto;
        }

        & img {
            width: 40vw;
            border-radius: 8px;
        }

        & p {
            margin: 0;
            font-family: "Manrope", sans-serif;
            font-weight: 300;
            font-style: normal;
            font-size: 11px;
            color: $c-white;
            display: flex;
            justify-content: center;
        }

        & a {
            text-decoration: none;
        }
    }
}

@media (min-width : 750px) {
    .modal {

        &__h2 {
            margin-bottom: 5%;

            &--extralight {
                font-size: 52px;
            }

            &--extrabold {
                font-size: 69px;
            }
        }

        &__construction {
            font-family: "Manrope", sans-serif;
            font-style: normal;
            display: flex;
            justify-content: center;
            color: $c-white;
            text-transform: uppercase;
            margin-top: 39px;
            font-size: 27px;
        }

        &__contenu {
            max-width: 80%;
            margin: 0 auto;
        }

        &__img {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 39px;
        }

        &__projet {
            display: flex;
            justify-content: center;
        }

        &__listUtils {
            max-width: 80%;
            margin: 0 auto;
            margin-top: 22px;
            margin-bottom: 52px;
        }

        &__el {
            border: solid 2px $c-white;
            border-radius: 5px;
            font-size: 17px;
            padding: 6px;
        }

        &__paragraph {
            font-family: "Manrope", sans-serif;
            font-style: normal;
            color: $c-white;
            font-size: 22px;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 300;
        }

        &__paraImg {
            font-size: 24px;
        }

        &__listButton {
            margin: 0;
            padding: 0;
            margin-top: 52px;
        }

        &__elButton {
            font-size: 22px;
            background-size: 22px;
            padding: 11px;
            padding-top: 9px;

            &:nth-child(1) {
                margin-bottom: 22px;
            }

            &:nth-child(2) a {
                background-position: 97%;
            }

            a {
                color: $c-white;
                text-decoration: none;
                background-image: url(../assets/images/button_site_goTo.svg);
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: 95%;
                padding: 7px;
                padding-top: 8px;
                padding-right: 46px;
            }
        }

        &__pucePara {
            width: 30px;
            height: 30px;
            margin-top: 20px;
            margin-bottom: 20px;
            background-image: url(../assets/images/puce.svg);
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__video {
            margin-top: 52px;
            margin-bottom: 7px;
            width: 90vw;
        }

        &__group {
            font-size: 17px;
        }

        &-content {
            padding: 5%;
        }

        &--open {
            overflow: hidden;

            header {
                display: none;
            }

            .bar {
                display: none;
            }

            .modal {
                overflow: scroll;
            }
        }

        &__close {
            top: 39px;
            right: 39px;
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            position: fixed;

            img {
                width: 30px;
            }
        }

        &__suggestionTitle {
            margin-top: 84px;
            margin-bottom: 63px;
            font-size: 36px;
        }

        &__suggestionImage {

            & img {
                width: 30vw;
            }

            & p {
                font-size: 20px;
            }
        }
    }




}

@media (min-width : 1200px) {

    .modal {

        &__h2 {
            margin-bottom: 10%;

            &--extralight {
                font-size: 52px;
            }

            &--extrabold {
                font-size: 69px;
            }
        }

        &__construction {
            font-family: "Manrope", sans-serif;
            font-style: normal;
            display: flex;
            justify-content: center;
            color: $c-white;
            text-transform: uppercase;
            font-size: 36px;
        }

        &__contenant {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
        }

        &__contenu {
            max-width: 100%;
        }

        &--left {
            max-width: 50%;
            margin: 0 auto;
        }

        &--right {
            max-width: 50%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__img {
            width: 40vw;
            margin: 0;
            margin-top: 78px;
        }

        &__projet {
            display: flex;
            justify-content: center;
        }

        &__pucePara {
            width: 30px;
            height: 30px;
            margin-top: 30px;
            margin-bottom: 30px;
            background-image: url(../assets/images/puce.svg);
            background-size: 20px;
        }

        &__listUtils {
            max-width: 30vw;
            margin: 0 auto;
            margin-top: 29px;
            margin-bottom: 52px;
        }

        &__el {
            border-top: solid 2px $c-white;
            border-radius: 10px;
            font-size: 20px;
            padding: 7px;
        }

        &__paragraph {
            font-family: "Manrope", sans-serif;
            font-style: normal;
            color: $c-white;
            font-size: 22px;
            margin-top: 0;
            margin-bottom: 0;
            max-width: 60%;
            font-weight: 300;
        }

        &__paraImg {
            font-size: 27px;
            max-width: 80%;
        }

        &__listButton {
            margin: 0;
            padding: 0;
            margin-top: 52px;
            display: flex;
            justify-content: center;
        }

        &__elButton {
            font-size: 22px;
            background-size: 22px;
            padding: 11px;
            padding-top: 9px;

            &:nth-child(1) {
                margin-right: 22px;
                margin-bottom: 0px;
            }

            &:nth-child(2) a {
                background-position: 96%;
            }

            a {
                color: $c-white;
                text-decoration: none;
                background-image: url(../assets/images/button_site_goTo.svg);
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: 94%;
                padding: 11px;
                padding-top: 8px;
                padding-right: 46px;
            }
        }

        &__video {
            margin-top: 52px;
            margin-bottom: 11px;
            width: 70%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }

        &__group {
            font-size: 22px;
            max-width: 100%;
            display: flex;
            justify-content: center;
        }

        &-content {
            padding: 5%;
            padding-top: 52px;
        }

        &--open {
            overflow: hidden;

            header {
                display: none;
            }

            .bar {
                display: none;
            }

            .modal {
                overflow: scroll;
            }
        }

        &__close {
            top: 69px;
            right: 69px;
            background: none;
            border: none;
            margin: 0;
            padding: 0;

            img {
                width: 39px;
            }
        }

        &__suggestionTitle {
            margin-top: 123px;
            margin-bottom: 93px;
            font-size: 52px;
        }

        &__suggestionImage {
            &--1 {
                padding-left: 10%;
            }

            &--2 {
                padding-right: 10%;
            }

            & img {
                width: 25vw;
                transition: transform 0.3s ease-in-out;

                &:hover {
                    transform: translateY(-10px);
                }
            }

            & p {
                font-size: 22px;
            }
        }
    }
}