.annexe {
    margin-bottom: 20vh;

    &__title {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        margin: 0;
        padding-top: 84px;
        margin-bottom: 84px;
        color: $c-white;
        font-weight: 800;
        font-size: 47px;
        line-height: 63px;
    }

    &__border {
        width: 100%;
        height: 1px;
        border-radius: 5px;
        background-color: $c-white;
    }

    &__h2 {
        font-family: "Orbitron", sans-serif;
        font-style: normal;
        color: $c-white;
        margin-top: 84px;
        margin-bottom: 63px;
        font-size: 27px;

        &--test {
            display: none;
        }
    }

    &__button {
        background-image: url("../assets/images/play.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.3s;
        border: none;
        background-color: transparent;
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }

    &__img {
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }

    &__jeu {
        display: none;
        grid-template-columns: repeat(3, 30vw);
        grid-template-rows: repeat(3, 30vw);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: 0 auto;
        margin-top: 47px;
        justify-content: center;
    }

    &__grid {

        &--1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        &--2 {
            grid-area: 1 / 3 / 2 / 4;
        }

        &--3 {
            grid-area: 3 / 1 / 4 / 2;
        }

        &--4 {
            grid-area: 3 / 3 / 4 / 4;
        }

        &--5 {
            grid-area: 2 / 2 / 3 / 3;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    &__buttonImg {
        padding: 0;
        border: none;
        background-color: transparent;
    }

    &__instructions {
        margin-bottom: 20vh;

        & .annexe__buttonNext {
            display: flex;
            margin: 0 auto;
            margin-top: 63px;
        }
    }

    &__instructionsSubtitle {
        font-family: "Manrope", sans-serif;
        font-weight: 800;
        font-style: normal;
        color: $c-white;
        font-size: 20px;
    }

    &__instructionsList {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
        padding-left: 20px;
    }

    &__instructionsEl {
        margin-top: 10px;
        color: $c-white;
        list-style: circle;
        line-height: 140%;
    }

    &__scoreBest {
        margin-top: 20px;
    }

    &__score {
        margin-top: 47px;
    }

    &__buttonNext {
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        z-index: 1;
        color: white;
        width: fit-content;
        padding-bottom: 12px;
        overflow: hidden;
        padding: 10px 25px;
        position: relative;
        z-index: 2;
        color: white;
        text-decoration: none;
        transition: color 0.3s ease;
        margin-top: 63px;

        &--anim {
            font-family: "Manrope", sans-serif;
            background: transparent;
            transition: all 0.3s ease;
            position: relative;
            display: inline-block;
            font-size: 15px;
        }

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            z-index: -1;
            background: rgb(255, 255, 255);
            transition: all 0.3s ease;
        }

        &:hover:after {
            top: auto;
            bottom: 0;
            height: 100%;
        }

        &:hover {
            color: black;
        }
    }

    &__text {
        font-family: "Manrope", sans-serif;
        font-weight: 800;
        font-style: normal;
        color: $c-white;
        font-size: 20px;
        margin-bottom: 0;
        margin-top: 0;
        justify-content: center;
        text-align: center;
        display: flex;
    }

    &__rechercheText {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 1px;
        margin-bottom: 63px;

        &--block {
            margin-top: 15px;
            display: block;
        }
    }

    &__scoreBest {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 300;
    }

    &__result {
        display: none;
        margin-top: 15vh;

        & .annexe__buttonNext {
            display: flex;
            margin: 0 auto;
            margin-top: 63px;
        }
    }

    &__compteur {
        display: none;
    }

    &__compteurPara {
        font-family: "Manrope", sans-serif;
        font-weight: 800;
        font-style: normal;
        color: $c-white;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 1px;
        display: flex;
        margin: 0 auto;
    }

    &__answer--correct {
        border: 2px solid rgb(0, 255, 0);
    }

    &__answer--incorrect {
        border: 2px solid red;
    }
}

@media (min-width : 750px) {
    .annexe {
        &__title {
            padding-top: 165px;
            margin-bottom: 123px;
            font-size: 93px;
            line-height: 112px;
        }

        &__border {
            height: 2px;
        }

        &__h2 {
            margin-top: 84px;
            margin-bottom: 63px;
            font-size: 36px;
        }

        &__buttonNext {
            padding: 15px 25px;
            padding-bottom: 17px;
            font-size: 20px;
            margin-top: 69px;
        }

        &__instructions {
            & .annexe__buttonNext {
                margin-top: 69px;
            }
        }

        &__result {
            & .annexe__buttonNext {
                margin-top: 69px;
            }
        }

        &__scoreBest {
            margin-top: 22px;
        }

        &__button {
            height: 85%;
            width: 85%;
        }

        &__jeu {
            grid-template-columns: repeat(3, 25vw);
            grid-template-rows: repeat(3, 25vw);
        }

        &__score {
            margin-top: 52px;
        }

        &__instructionsSubtitle {
            font-size: 22px;
            margin-bottom: 27px;
        }

        &__instructionsList {
            font-size: 17px;
            padding: 0;
        }

        &__instructionsEl {
            margin-top: 15px;
        }

        &__text {
            font-size: 29px;
        }

        &__scoreBest {
            font-size: 22px;
            margin-top: 22px;
        }

        &__rechercheText {
            font-size: 20px;
            margin-bottom: 84px;
            max-width: 80%;

            &--block {
                margin-top: 20px;
                display: block;
            }
        }

        &__compteurPara {
            font-size: 27px;
        }

        &__answer--correct {
            border-width: 3px;
        }

        &__answer--incorrect {
            border-width: 3px;
        }
    }
}

@media (min-width : 1200px) {
    .annexe {
        &__title {
            padding-top: 165px;
            margin-bottom: 52px;
            position: relative;
            font-size: 93px;
        }

        &__border {
            margin: 0;
            width: 33%;
        }

        &__h2 {
            margin-top: 123px;
            margin-bottom: 93px;
            font-size: 52px;
        }

        &__button {
            width: 70%;
            height: 70%;
        }

        &__img {
            border-radius: 7px;
        }

        &__jeu {
            margin-top: 52px;
            margin-bottom: 123px;
            grid-template-columns: repeat(3, 12vw);
            grid-template-rows: repeat(3, 12vw);
        }

        &__scoreBest {
            margin-top: 29px;
        }

        &__buttonNext {
            padding: 15px 25px;
            padding-bottom: 17px;
            font-size: 22px;
            margin-top: 123px;
        }

        &__instructions {
            margin-top: 123px;
            margin-bottom: 123px;

            & .annexe__buttonNext {
                margin-top: 123px;
            }
        }

        &__result {
            margin-top: 123px;
            margin-bottom: 20vh;

            & .annexe__buttonNext {
                margin-top: 123px;
            }
        }

        &__instructionsSubtitle {
            font-size: 29px;
            margin-bottom: 52px;
        }

        &__instructionsList {
            font-size: 22px;
        }

        &__instructionsEl {
            margin-top: 22px;
        }

        &__text {
            font-size: 39px;
        }

        &__scoreBest {
            font-size: 22px;
            margin-top: 29px;
        }

        &__rechercheText {
            font-size: 22px;
            margin-bottom: 20vh;
            max-width: 60%;

            &--block {
                margin-top: 22px;
                display: block;
            }
        }

        &__compteurPara {
            font-size: 39px;
        }
    }
}