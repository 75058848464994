.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 63px;
    margin-bottom: 63px;

    &__list {
        display: flex;
        transition: transform 0.5s ease-in-out;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__el {
        min-width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    &__el img {
        width: 200px;
    }

    &__prev,
    &__next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
    }

    &__prev {
        left: 10px;
    }

    &__next {
        right: 10px;
    }

    &__prev img,
    &__next img {
        width: 20px;
    }
}

@media (min-width : 1200px) {
    .slider {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 93px;
        margin-bottom: 93px;

        &__list {
            display: flex;
            transition: transform 0.5s ease-in-out;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        &__el {
            min-width: 100%;
            box-sizing: border-box;
            text-align: center;
        }

        &__el img {
            width: 300px;
        }

        &__prev,
        &__next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
        }

        &__prev {
            left: 10px;
        }

        &__next {
            right: 10px;
        }

        &__prev img,
        &__next img {
            width: 20px;
        }
    }
}