.projets {
    margin-bottom: 35vh;

    &__border {
        width: 100%;
        height: 1px;
        border-radius: 5px;
        background-color: $c-white;
    }

    &__h2 {
        font-family: "Orbitron", sans-serif;
        font-style: normal;
        color: $c-white;
        margin-top: 84px;
        margin-bottom: 63px;
        font-size: 27px;
    }

    &__img {
        width: 70vw;
        border-radius: 25px;
        display: flex;
        justify-content: center;
    }

    &__description {
        color: $c-white;
        margin-top: 20px;
        margin-bottom: 0;
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
    }

    &__content {
        margin-left: 50px;

        a {
            text-decoration: none;
        }
    }

    &__slider {
        display: flex;
        justify-content: start;
    }
}

@media (min-width : 750px) {
    .projets {
        margin-bottom: 35vh;

        &__border {
            height: 2px;
        }

        &__h2 {
            margin-top: 84px;
            margin-bottom: 63px;
            font-size: 36px;
        }

        &__img {
            width: 60vw;
        }

        &__description {
            font-size: 22px;
        }

        &__content {
            margin-left: 63px;
        }
    }
}

@media (min-width : 1200px) {

    .projets {
        margin-bottom: 30vh;

        &__border {
            width: 33%;
        }

        &__h2 {
            margin-top: 123px;
            margin-bottom: 93px;
            font-size: 52px;
        }

        &__description {
            color: $c-white;
            font-size: 22px;
            margin-top: 22px;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
        }

        &__content {
            margin-left: 50px;
        }

        &__slider {
            width: 200%;
            display: flex;
            justify-content: center;
        }

        &__img {
            max-width: 100%;
            width: 30vw;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: translateY(-10px);
            }
        }

    }

    @media (max-height : 800px) {
        .projets {

            &__description {
                font-size: 17px;
            }
        }
    }
}