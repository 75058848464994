.contact {
    &__reseau li:hover img {
        transform: scale(1.1);
        transition: transform 0.3s ease;
    }
}

.nav {
    &__el {
        display: table-cell;
        position: relative;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }

        & a:after {
            bottom: -3px;
            content: "";
            height: 1px;
            left: 50%;
            border-radius: 5px;
            position: absolute;
            background: $c-white;
            transition: width 0.4s ease 0s, left 0.4s ease 0s;
            width: 0;
        }

        & a:hover:after {
            width: 100%;
            left: 0;
        }

        &--active a:after {
            width: 100%;
            left: 0;
        }
    }
}