::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: $c-bg;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  overflow-x: hidden;
}

p {
  line-height: 140%;
}

main {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.bar {
  position: fixed;
  height: 2px;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  bottom: 0;
  z-index: 50;

  &--progress {
    width: 0%;
    height: 2px;
    background-color: $c-white;
  }
}

@media (min-width: 750px) {
  main {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  main {
    padding-left: 93px;
    padding-right: 93px;
  }

  html {
    cursor: none;
  }

  .circle {
    z-index: 1001;
    --circle-size: 40px;
    position: fixed;
    height: 40px;
    width: 40px;
    border: 2px solid white;
    border-radius: 100%;
    top: calc(40px / 2 * -1);
    left: calc(40px / 2 * -1);
    pointer-events: none;
    transition: width 0.3s ease, height 0.3s ease;
    mix-blend-mode: difference;
  }

  a,
  button {
    cursor: none;
  }


  .circle.hover {
    height: 60px;
    width: 60px;
    top: calc(60px / 2 * -1);
    left: calc(60px / 2 * -1);
  }

}