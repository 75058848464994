.contact {
    &__border {
        width: 100%;
        height: 1px;
        border-radius: 5px;
        background-color: $c-white;
    }

    &__h2 {
        font-family: "Orbitron", sans-serif;
        font-style: normal;
        color: $c-white;
        margin-top: 84px;
        margin-bottom: 63px;
        font-size: 27px;
    }

    &__list {
        padding: 0;
    }

    &__text {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
        margin-bottom: 36px;
    }

    &__list--bottom {
        padding: 0;
        margin: 0;
    }

    &__adresse,
    &__name {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: $c-white;
        font-size: 15px;
    }

    &__adresse {
        margin-bottom: 36px;

        a {
            color: $c-white;
            text-decoration: none;
        }
    }

    &__reseau {
        display: flex;
        max-width: 60%;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 27px;
    }

    &__img {
        height: 20px;
    }

    &__copyright {
        font-family: "manrope", sans-serif;
        font-weight: 400;
        color: $c-white;
        font-size: 11px;
        text-align: center;
        margin-bottom: 20px;

        a {
            color: $c-white;
        }
    }

    &__buttonAnnexe {
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        color: white;
        width: fit-content;
        padding-bottom: 12px;
        overflow: hidden;
        margin-bottom: 47px;

        &--anim {
            padding: 10px 0;
            font-family: "Manrope", sans-serif;
            background: transparent;
            transition: all 0.3s ease;
            position: relative;
            display: inline-block;
            font-size: 15px;
        }

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            z-index: -1;
            background: rgb(255, 255, 255);
            transition: all 0.3s ease;
        }

        &:hover:after {
            top: auto;
            bottom: 0;
            height: 100%;
        }

        & a {
            padding: 10px 25px;
            position: relative;
            z-index: 2;
            color: white;
            text-decoration: none;
            transition: color 0.3s ease;
        }

        &:hover a {
            color: black;
        }
    }
}

li {
    list-style: none;
}

@media (min-width : 750px) {
    .contact {
        &__border {
            height: 2px;
        }

        &__h2 {
            margin-top: 84px;
            margin-bottom: 63px;
            font-size: 36px;
        }

        &__text {
            font-size: 20px;
            max-width: 90%;
        }

        &__adresse,
        &__name {
            font-size: 20px;
        }

        &__adresse {
            margin-bottom: 36px;
        }

        &__reseau {
            max-width: 30%;
        }

        &__img {
            height: 27px;
        }

        &__copyright {
            font-size: 20px;
        }

        &__buttonAnnexe {
            padding-bottom: 17px;

            &--anim {
                padding: 15px 0;
                font-size: 20px;
            }

            & a {
                padding: 15px 25px;
            }
        }
    }

}

@media (min-width : 1200px) {
    .contact {

        &-desktop {
            display: block;
        }

        &__list {
            display: flex;
            flex-direction: column;

            &--top {
                margin: 0;
                padding: 0;
            }

            &--bottom {
                display: flex;
                justify-content: space-between;
                width: 50vw;
                padding: 0;
                margin: 0;
                align-items: flex-end;
                margin-bottom: 69px;
            }
        }

        &__adresse {
            margin: 0;
        }

        &__border {
            width: 33%;
        }

        &__h2 {
            margin-top: 123px;
            margin-bottom: 93px;
            font-size: 52px;
        }

        &__text {
            font-size: 22px;
            width: 50%;

            & p {
                margin-top: 0;
            }
        }

        &__adresse,
        &__name {
            font-size: 22px;
        }

        &__reseau {
            width: 25%;
            margin: 0;
            padding: 0;
            margin-bottom: 93px;
        }

        &__img {
            height: 29px;
            margin-right: 22px;
        }

        &__copyright {
            font-size: 17px;
            margin: 0;

            a {
                color: $c-white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__buttonAnnexe {
            padding-bottom: 17px;

            &--anim {
                padding: 15px 0;
                font-size: 22px;
            }
        }
    }

}