.propos {
    margin-bottom: 30vh;

    &__border {
        width: 100%;
        height: 1px;
        border-radius: 5px;
        background-color: $c-white;
    }

    &__h2 {
        font-family: "Orbitron", sans-serif;
        font-style: normal;
        color: $c-white;
        margin-top: 84px;
        margin-bottom: 63px;
        font-size: 27px;
    }

    &__text {
        margin: 0;
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 20px;
        color: $c-white;
    }

    &__img {
        display: flex;
        justify-content: center;
        width: 70%;
        margin: auto;
        margin-top: 20px;
    }

    &__perso {
        width: 50%;
        margin-top: 20px;
    }
}

@media (min-width: 750px) {
    .propos {
        margin-bottom: 35vh;

        &__border {
            height: 2px;
        }

        &__h2 {
            margin-top: 84px;
            margin-bottom: 63px;
            font-size: 36px;
        }

        &__text {
            font-size: 27px;
        }

        &__img {
            width: 60%;
            margin: auto;
            margin-top: 20px;
        }
    }
}

@media (min-width: 1200px) {
    .propos {
        margin-bottom: 30vh;

        &__border {
            width: 33%;
        }

        &__h2 {
            margin-top: 123px;
            margin-bottom: 93px;
            font-size: 52px;
        }

        &__text {
            font-size: 29px;
            width: 50%;
        }

        &__img {
            width: 40%;
        }

        &__container {
            display: flex;
            flex-direction: initial;
        }
    }

    @media (max-height : 800px) {
        .propos {

            &__text {
                font-size: 22px
            }

            &__img {
                width: 30%;
            }
        }
    }
}